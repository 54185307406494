<template>
  <div class="People_box">
    <div class="People">
      <ul>
        <!-- <li @click="Essential()" class="red">全部人员2</li>
           <li @click="structure()">部门结构3</li> -->
        <li
          v-for="(item, index) in data"
          :key="index"
          @click="
            red = index
            compData = item.components
          "
          :class="{ red1: red === index }"
        >
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>
      <component :is="compData"></component>
  </div>
</template>

<script>
import qbry from '@c/ToggleSetting/messageEssential'
import bmjg from '@c/ToggleSetting/messageStructure'
export default {
  components: {
    qbry,
    bmjg
  },
  data() {
    return {
      compData: 'qbry',
      red: 0,
      data: [
        { name: '全部人员', components: 'qbry' },
        { name: '部门结构', components: 'bmjg' }
      ]
    }
  },
  methods: {
    Essential() {
      this.compData = 'qbry'
    },
    structure() {
      this.compData = 'bmjg'
    }
  }
}
</script>

<style scoed lang="scss">
.el-main {
  background: #f2f6fc;
}
.People_box {
  position: relative;
}
.People {
  position: absolute;
  top: 56px;
  width: 80%;
  color: #f6f6f3;
  background-size: 100% 800px;
  & > ul {
    display: flex;
    & > li {
      color: #333;
      font-size: 20px;
      width: 300px;
      text-align: center;
    }

    .red1 {
      color: red;
    }
  }
}
</style>
