<template>
  <div class="Enter-box rs">
    <div class="Essential">
      <div class="Essential-boxS">
        <!-- <ul class="Essential-ul">
               <li class="Essential-li">全部人员</li>
               <li  @click="structure()" class="">部门结构</li>
            </ul> -->
        <div class="Essential-firm">
          <p v-if="$store.state.qyInfo">
            <span class="Essential-noe">当前企业：{{$store.state.ent_name}}</span>


          </p>
          <p>
            <span @click="qiehuan=false">当前总人数：{{numObj.staff_num}}，</span> &nbsp;&nbsp;&nbsp;
            <span @click="qiehuan=true">未注册：{{numObj.staff_num_register_no}} 人,</span>

          </p>
        </div>
      </div>
      <div class="Essential-state">
        <div class="Essential-state-boxS">
          <ul>
            <li @click="role = true"><i></i><span>添加人员</span></li>
            <li @click="editroleFu"><i></i><span>调整部门人员</span></li>
            <li @click="importDialog"><i></i><span>导入人员</span></li>
            <li @click="exportDialogShow = true "><i></i><span>导出数据</span></li>
            <li @click="LZ" v-if="ActiveValue!='0'"><i></i><span>离职</span></li>
            <li @click="fZ" v-if="ActiveValue=='0'"><i></i><span>复职</span></li>
            <li>
              <el-select v-model="shop_id" placeholder="所属门店" @change="GetSatffList">
                <el-option :label="'全部门店'" :value="'0'">
                </el-option>
                <el-option v-for="item in MDList" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                </el-option>
              </el-select>
            </li>
            <li>
              <el-select v-model="ActiveValue" placeholder="在职" @change='GetSatffList'>
                <el-option v-for="item in LZoptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </li>
          </ul>


          <div class="message-search">
            <i></i>
            <el-input v-model="nameA" tpye="text" placeholder="搜索人员或部门" @input='GetSatffList'>></el-input>
          </div>
        </div>
        <div class="message-section">
          <div class="message-section-top">
            <el-table ref="multipleTable" :data="staffList" tooltip-effect="dark" style="width: 100%" height="450"
              @selection-change="handleSelectionChange" @row-click='handleSelectionChange2'>
              <el-table-column type="selection" width="55" :selectable="checkBoxDisable"> </el-table-column>
              <el-table-column prop="shop_name" label="所属门店" width="100"></el-table-column>
              <el-table-column prop="name" label="人员" width="100"> </el-table-column>
              <el-table-column prop="sex" label="性别" width="60">
                <template slot-scope="scope">
                  <p v-show="scope.row.sex=='1'">男</p>
                  <p v-show="scope.row.sex=='0'">女</p>
                </template>
              </el-table-column>
              <el-table-column prop="dept_name" label="部门" width="100">
              </el-table-column>
              <el-table-column prop="post_name" label="职位" width="100">
              </el-table-column>
              <el-table-column prop="hired_date" label="入职时间" width="150">
              </el-table-column>
              <el-table-column prop="mobile" label="手机号" width="140">
              </el-table-column>
              <el-table-column label="地区" width="240">
                <template slot-scope="scope">
                  <span>{{scope.row.province}}</span>
                  <span>{{scope.row.city}}</span>
                  <span>{{scope.row.district}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="邮箱" width="240">
              </el-table-column>
              <el-table-column label="注册状态">
                <template slot-scope="scope">
                  <p :style="scope.row.register_flag==1?'':'color:rgb(248, 110, 110)'">
                    {{ scope.row.register_flag==1?'已注册':(scope.row.register_flag==0?'未注册':'')}}</p>
                </template>
              </el-table-column>
              <el-table-column prop="sex" label="就职" width="140">
                <template slot-scope="scope">
                  <p v-show="scope.row.active=='1'">在职</p>
                  <p style="color: rgb(248, 110, 110);" v-show="scope.row.active=='0'">离职</p>
                </template>
              </el-table-column>
            </el-table>


          </div>
        </div>

        <el-drawer title="新增员工" :visible.sync="role" ref="role" :with-header="false" :close-on-press-escape='false'
          :wrapperClosable='false'>
          <div class="role">
            <div class="Character-tltle">
              <span></span>
              <p>新增员工</p>
            </div>
            <div class="role-input">
              <div>
                <span><span style="color:red">*</span>人员姓名：</span>
                <el-input v-model="AddPre.name" placeholder="请输入"></el-input>
              </div>

              <div>
                <span><span style="color:red">*</span>性别：</span>
                <el-select v-model="AddPre.sex" placeholder="请选择">
                  <el-option :label="'男'" :value="'1'">
                  </el-option>
                  <el-option :label="'女'" :value="'0'">
                  </el-option>
                </el-select>
              </div>

              <div class="boxS-box">
                <span><span style="color:red">*</span>所属门店：</span>
                <!-- <el-select v-model="AddPre.shop_id" placeholder="所属门店" >
                  <el-option v-for="item in MDList" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                  </el-option>
                </el-select> -->

                <el-cascader v-model="AddPre.shop_id" :options="MDList" :props="{ multiple: true, checkStrictly: true,children: 'list',
        label: 'shop_name',value:'shop_id' }" clearable></el-cascader>
              </div>
              <div class="boxS-box">
                <span><span style="color:red">*</span>部门：</span>


                <el-cascader v-model="AddPre.dept_id" :options="DeptList" :props="{ multiple: true, checkStrictly: true,children: 'list',
        label: 'dept_name',value:'dept_id' }" clearable></el-cascader>
              </div>
              <div>
                <span><span style="color:red">*</span>职位：</span>
                <el-input v-model="AddPre.post_name" placeholder="请输入"></el-input>
              </div>
              <div>
                <span><span style="color:red">*</span>入职时间：</span>
                <div class="block">
                  <!-- <span class="demonstration">默认</span> -->
                  <el-date-picker v-model="AddPre.hired_date" type="date" :picker-options="pickerOptions0"
                    value-format="yyyy-MM-dd" placeholder="选择日期">
                  </el-date-picker>
                </div>
              </div>
              <div>
                <span><span style="color:red">*</span>手机号：</span>
                <el-input v-model="AddPre.mobile" @input='limitOrder2' type="number" placeholder="请输入"></el-input>
              </div>
              <div>
                <span><span style="color:red">*</span>地区：</span>

                <!-- <el-cascader v-model="value" :options="AddressList" :props="{ label:'name',value:'value' }"  ></el-cascader> -->
                <!-- <el-cascader v-model="item.value" :options="JSON.parse(item.props).options"></el-cascader> -->
                <v-distpicker class="n_jilian" :province="AddPre.province" :city="AddPre.city" :area="AddPre.district"
                  @selected="onSelected">
                </v-distpicker>
                <!-- <el-input placeholder="请输入" v-model="AddPre.area"></el-input> -->
              </div>
              <div>
                <span><span style="color:red">*</span>邮箱：</span>
                <el-input v-model="AddPre.email" placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="admin-alter">
              <a class="admin-confirm" @click="role_confirm">确定</a>
              <a class="admin-cancel" @click="role_cancel">取消</a>
            </div>
          </div>
        </el-drawer>

        <el-drawer title="编辑员工" :visible.sync="editrole" ref="role" :with-header="false" :close-on-press-escape='false'
          :wrapperClosable='false'>
          <div class="role height ">
            <div class="Character-tltle">
              <span></span>
              <p>编辑员工</p>
            </div>
            <div class="role-input">
              <div>
                <span><span style="color:red">*</span>人员姓名：</span>
                <el-input v-model="AddPre.name" placeholder="请输入"></el-input>
              </div>
              <div>
                <span><span style="color:red">*</span>性别：</span>
                <el-select v-model="AddPre.sex" placeholder="请选择">
                  <el-option :label="'男'" :value="'1'">
                  </el-option>
                  <el-option :label="'女'" :value="'0'">
                  </el-option>
                </el-select>
              </div>
              <div class="boxS-box">
                <span><span style="color:red">*</span>所属门店：</span>
                <el-cascader v-model="AddPre.shop_id" :options="MDList" :props="{ multiple: true, checkStrictly: true,children: 'list',
        label: 'shop_name',value:'shop_id' }" clearable></el-cascader>
                <!-- <el-select v-model="AddPre.shop_id" placeholder="所属门店">
                  <el-option v-for="item in MDList" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                  </el-option>
                </el-select> -->
              </div>
              <div class="boxS-box">
                <span><span style="color:red">*</span>部门：</span>
                <el-cascader v-model="AddPre.dept_id" :options="DeptList" :props="{ multiple: true, checkStrictly: true,children: 'list',
        label: 'dept_name',value:'dept_id' }" clearable></el-cascader>
              </div>
              <div>
                <span><span style="color:red">*</span>职位：</span>
                <el-input v-model="AddPre.post_name" placeholder="请输入"></el-input>
              </div>
              <div>
                <span><span style="color:red">*</span>入职时间：</span>
                <div class="block">
                  <!-- <span class="demonstration">默认</span> -->
                  <el-date-picker v-model="AddPre.hired_date" :picker-options="pickerOptions0" type="date"
                    value-format="yyyy-MM-dd" placeholder="选择日期">
                  </el-date-picker>
                </div>
              </div>
              <div>
                <span><span style="color:red">*</span>手机号：</span>
                <el-input v-model="AddPre.mobile" type="number" placeholder="请输入"></el-input>
              </div>
              <div>
                <span><span style="color:red">*</span>地区：</span>

                <!-- <el-cascader v-model="value" :options="AddressList" :props="{ label:'name',value:'value' }"  ></el-cascader> -->
                <!-- <el-cascader v-model="item.value" :options="JSON.parse(item.props).options"></el-cascader> -->

                <!-- <el-input placeholder="请输入" v-model="AddPre.area"></el-input> -->
                <v-distpicker class="n_jilian" :province="AddPre.province" :city="AddPre.city" :area="AddPre.district"
                  @selected="onSelected">
                </v-distpicker>
              </div>
              <div>
                <span><span style="color:red">*</span>邮箱：</span>
                <el-input v-model="AddPre.email" placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="admin-alter buttons">
              <a class="admin-confirm button1" @click="role_confirm">确定</a>
              <a class="admin-cancel button2" @click="role_cancel">取消</a>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>


    <el-dialog title="调整部门" :visible.sync="centerDialogVisible" width="30%" center>
      <el-tree :data="DeptList" show-checkbox node-key="dept_id" ref="tree2" :check-strictly='true'
        :props="defaultProps">
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false; GetSatffList()">取 消</el-button>
        <el-button type="primary" @click="clickSure">确 定</el-button>
      </span>
    </el-dialog>



    <!-- 导入   和导出  导入 导入  导入  导入 导入  -->
    <el-dialog title="导入人员信息" :visible.sync="importDialogShow" ref="dialogVisible" width="60%" v-if="importDialogShow">
      <!-- :before-close="handleClose" -->
      <!-- <p>点击 <span style="color:#FC4353" @click="MuMan_XiaZai">下载导入模板文件</span> </p> -->
      <!-- 下载模版 -->
      <import-file ref="submit" piliangdaoru="部门"></import-file>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="QueRenShangChuan">确定上传</el-button>
      </span>
    </el-dialog>
    <!-- 导出 -->
    <el-dialog title="导出人员信息" :visible.sync="exportDialogShow" ref="dialogVisible" width="60%" v-if="exportDialogShow">
      <!-- :before-close="handleClose" -->
      <!-- <p>点击 <span style="color:#FC4353" @click="MuMan_XiaZai">下载导入模板文件</span> </p> -->
      <!-- 下载模版 -->
      <export-file ref="downFile" leixing="人员"></export-file>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="exportDialogDown">导出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    insert_staff,
    update_staff,
    update_staff_active_batch,
    query_dept_staff_tree_aim,
    query_dept_tree,
    query_ent_primary_manager_detail,
    batch_set_dept_for_many_staff,
  } from "../../api/pulic"



  import {
    query_staff_list_register_not
  } from '../../api/public'
  import {
    query_shop_info_list
  } from '../../api/store'

  import {

    query_dept_staff_list,
    set_ent_dept_info,
    query_ent_staffNum_registerNum,
    query_ent_dept_list_of_all,
    query_ent_staff_list,
    set_ent_primary_manager,
    query_ent_primary_manager,
    update_ent_primary_manager,
    query_ent_sub_manager_list_by_module,
    get_dept_list,
    query_all_staff_list_of_dept,
    query_dept_staff_tree_aim_not,
    query_user_info,
    query_ent_sub_manager_list,
    query_all_module_read_power_detail,
    set_ent_sub_manager,
    delete_ent_sub_manager,
  } from '../../api/foodqiye'




  // import {address} from '../../assets/js/china_address'

  import vDistpicker from "v-distpicker"
  import importFile from "@c/modules/daorudaochu/Imports"
  import exportFile from "@c/modules/daorudaochu/Exportb"

  export default {
    data() {
      return {

        pickerOptions0: {
          disabledDate(time) {
            return time.getTime() > Date.now() - 8.64e6
          }
        },
        staffListA: [],
        qiehuan: false, // 切换 注册的/未注册的
        centerDialogVisible: false,
        headerCon: {
          tximg: require('@img/tx.png'),
          logo: require('@img/logo.png'),
          qhimg: require('@img/qiehuan.png'),
          tcimg: require('@img/tuichu.png'),
        },
        ActiveValue: '1',
        editrole: false,
        numObj: {
          staff_num_register_no: '0',
          staff_num: '0'
        },
        value: '',
        // AddressList:address,
        shop_id: '0',
        MDList: [],

        Sexoptions: [{
            value: '0',
            label: '性别(全部)'
          }, {
            value: '2',
            label: '男'
          },
          {
            value: '1',
            label: '女'
          }


        ],
        LZoptions: [{
            value: null,
            label: '全部'
          },
          {
            value: '1',
            label: '在职'
          }, {
            value: '0',
            label: '离职'
          }

        ],
        importDialogShow: false,
        exportDialogShow: false,
        DeptList: [],
        // 时间选择
        nameA: '',
        time: '',
        Zzvalue: '0',
        sexvalue: '0',
        MDValue: '0',
        staffList: [],
        role: false,
        multipleSelection: [],
        AddPre: {
          // kkkkkk
          sex: '',
          name: '',
          mobile: '',
          dept_name: '',
          dept_id: '',
          post_name: '',
          hired_date: '',
          email: '',
          // area: '',
          shop_id: '',
          // flag_in_charge:shifou,
          ent_id: '',
          user_id: '',
          active: '1',
          province: '', //门店地址：省
          city: '', //门店地址：市
          district: '', //门店地址：区
        },
        GMID: '',
        defaultProps: {
          children: 'list',
          label: 'dept_name'
        },

        TuPianUrl: '',
        url: 'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0',
      }
    },
    // computed(){

    //     staffList:function{

    //     }
    //   }
    // },
    watch: {
      qiehuan(newval, oldval) {
        console.log(newval);
        if (newval) {
          this.staffList = this.staffListA.filter(x => {
            return x.register_flag == '0'
          })
        } else {
          this.staffList = this.staffListA
        }
      }
    },


    created() {
      this.GetPAll()
      query_ent_primary_manager({
        data: {
          ent_id: this.$store.state.ent_id,
        }
      }).then(res => {

        this.GMID = res.body.data.p_mng_mobile
        this.GetSatffList()
      })
      this.getDepList()
      this.getShopList()
    },
    components: {
      vDistpicker,
      importFile,
      exportFile
    },
    methods: {
      selectYZC() {

        query_ent_staff_list({
          data: {
            active: "1",
            ent_id: this.$store.state.ent_id,
          }
        }).then(res => {
          this.staffList = res.body.data

        })
      },
      selectWZC() {

        query_staff_list_register_not({
          data: {
            active: "1",
            ent_id: this.$store.state.ent_id,
          }
        }).then(res => {
          this.staffList = res.body.data

        })

      },
      onSelected(val) {
        this.AddPre.province = val.province.value
        this.AddPre.city = val.city.value
        this.AddPre.district = val.area.value
        //
      },

      clickSure() {
        let arr = this.$refs.tree2.getCheckedNodes()
        if (!arr.length) {
          this.$message({
            message: '请选择部门',
            type: 'error'
          })
          return
        }

        let arr2 = []
        for (const i in arr) {
          arr2.push(arr[i].dept_id)
        }

        let arr3 = []
        let arr4 = []
        for (const i in this.multipleSelection) {
          arr4.push(this.multipleSelection[i].dept_id)
          arr3.push(this.multipleSelection[i].staff_id)
        }
        let arr5 = arr4.flat(Infinity)

        batch_set_dept_for_many_staff({
          data: {
            current_dept_id: arr5.join(','),
            ent_id: this.$store.state.ent_id,
            dept_id: arr2.join(','),
            staff_id: arr3.join(','),
            user_id:this.$store.state.user_id
          }
        }).then((res) => {
          this.$message({
            message: '调整部门成功',
            type: 'success'
          })
          this.centerDialogVisible = false
          this.GetSatffList()
          // this.numObj = res.body.data
          console.log('获取企业注册人数', res);
        })

        //         current_dept_id: ","
        // dept_id: "BM1615631453528srqa,BM1590410239260sxur,BM1597927884232iv13"
        // ent_id: "QY1590397634768k942"
        // staff_id: "YG1588346628750,YG1588346650638"
        // console.log(this.$refs.tree.getCheckedNodes());
      },
      GetPAll() {
        query_ent_staffNum_registerNum({
          data: {
            ent_id: this.$store.state.ent_id
          }
        }).then((res) => {
          this.numObj = res.body.data
          console.log('获取企业注册人数', res);
        })
      },
      limitOrder2() {
        // 要做长度判断
        if (this.AddPre.mobile.length > 11) {
          this.AddPre.mobile = this.AddPre.mobile.slice(0, 11)
          // this.order = this.$refs.order.value
        }
      },
      GetSatffList() {
        let data = {
          ent_id: this.$store.state.ent_id,
          // user_id: this.$store.state.user_id,
          active: this.ActiveValue,
          search_str: this.nameA
        }
        if (this.shop_id != '0') {
          data.shop_id = this.shop_id
        }
        if (this.nameA) {
          data.search_str = this.nameA
        }


        // query_dept_staff_list
        query_ent_staff_list({
          data
        }).then((res) => {


          if (res.code == '10149') {
            let arr = []
            res.body.data.map((item, index) => {
              if (item.mobile == this.GMID) {
                arr.unshift(item)
              } else {
                arr.push(item)
              }
            })

            this.staffListA = arr
            this.staffList = arr


            console.log('获取人员列表', res);

          } else {
            console.log('获取人员列表失败')
          }

        })


        this.GetPAll()
      },




      fZ() {
        if (!this.multipleSelection[0]) {
          this.$message({
            message: '请选择离职人员',
            type: 'error'
          })
          return
        }
        let data = {
          ent_id: this.$store.state.ent_id,
          user_id: this.$store.state.user_id,
          active: '1',
        }

        let a = []

        for (const i in this.multipleSelection) {
          this.multipleSelection[i].staff_id
          a.push(this.multipleSelection[i].staff_id)
        }

        data.staff_ids = a.join(',')
        update_staff_active_batch({
          data
        }).then((res) => {
          this.GetSatffList()
          this.$message({
            message: '已再职',
            type: 'success'
          })
        })

      },

      LZ() {
        if (!this.multipleSelection[0]) {
          this.$message({
            message: '请选择离职人员',
            type: 'error'
          })
          return
        }
        let data = {
          ent_id: this.$store.state.ent_id,
          user_id: this.$store.state.user_id,
          active: '0',
        }

        let a = []

        for (const i in this.multipleSelection) {
          this.multipleSelection[i].staff_id
          a.push(this.multipleSelection[i].staff_id)
        }

        data.staff_ids = a.join(',')

        this.$confirm('该人员是否离职', '确认信息', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确认离职',
            cancelButtonText: '放弃修改'
          })
          .then(() => {
            console.log(data);
            update_staff_active_batch({
              data
            }).then((res) => {
              this.GetSatffList()
              this.$message({
                message: '操作离职成功',
                type: 'success'
              })
              // this.staffList = res.body.data
              console.log('已离职', res);
            })
          })
          .catch(action => {
            this.$message({
              type: 'info',
              message: '取消离职操作'
            })
          });





      },



      checkBoxDisable(row) {
        if (row.mobile == this.GMID) {
          return false
        } else {
          return true
        }
        //
      },
      // ActiveValuechangeSelect() {
      //   let self = this
      //   let data = {
      //     ent_id: this.$store.state.ent_id

      //   }
      //   if (this.ActiveValue != '3') {
      //     data.active = this.ActiveValue
      //   }
      //   query_dept_staff_list({
      //     data
      //   }).then((res) => {
      //
      //     this.staffList = res.body.data
      //     console.log('获取人员列表', res);
      //   })

      // },
      editroleFu() {
        if (!this.multipleSelection.length) {
          this.$message({
            message: '请选择人员 ',
            type: 'error'
          })
          return
        }

        console.log(this.multipleSelection[0])
        this.centerDialogVisible = true


        let a = []

        this.multipleSelection[0].dept_id = this.multipleSelection[0].dept_id.split(',')

        // if (this.multipleSelection[0].dept_id.indexOf(',') > -1) {
        //   a = this.multipleSelection[0].dept_id.split(',')
        // } else {
        //   a = [this.multipleSelection[0].dept_id]
        // }
        this.$nextTick(() => {
          this.$refs.tree2.setCheckedKeys(this.multipleSelection[0].dept_id);
          // this.$refs.tree.setCheckedKeys(list)
        });

        // if (this.multipleSelection.length != 1) {
        //   this.$message({
        //     message: '请选择人员，并且只能编辑一人',
        //     type: 'error'
        //   })
        //   return
        // }
        // this.AddPre = this.multipleSelection[0]
        // this.editrole = true
      },
      // MDChange() {
      //   this.getstaffList()
      // },
      getShopList() {
        query_shop_info_list({
          data: {
            ent_id: this.$store.state.ent_id
          }
        }).then((res) => {
          this.MDList = res.body.data
          console.log('获取门店列表', res);
        })

      },
      andlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },

      getDepList() {



        query_dept_tree({
          data: {
            ent_id: this.$store.state.ent_id
          }
        }).then((res) => {

          this.DeptList = res.body.data

          console.log('获取部门列表', res);
        })
      },
      // getstaffList() {
      //   let self = this
      //   let data = {
      //     ent_id: this.$store.state.ent_id

      //   }
      //   if (this.shop_id != '0') {
      //     data.shop_id = this.shop_id
      //     query_dept_staff_list({
      //       data
      //     }).then((res) => {
      //
      //       this.staffList = res.body.data
      //       console.log('获取人员列表', res);
      //     })
      //   } else {
      //     query_ent_staff_list({
      //       data
      //     }).then((res) => {
      //
      //       this.staffList = res.body.data
      //       console.log('获取人员列表', res);
      //     })
      //   }

      // },
      disabledDate(time) {
        return time.getTime() > Date.now()
      },
      structure() {
        this.$router.push('/messageStructure')
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        } else {
          this.$refs.multipleTable.clearSelection()
        }
      },
      handleSelectionChange2(val) {
        //


        this.AddPre = JSON.parse(JSON.stringify(val))
        this.AddPre.current_dept_id=this.AddPre.dept_id
        if (this.AddPre.dept_id) {
          if (this.AddPre.dept_id instanceof Array) {

          } else {

            this.AddPre.dept_id = this.AddPre.dept_id.split(',')

          }
        }


        this.editrole = true
        // this.multipleSelection = val
      },
      handleSelectionChange(val) {
        //
        console.log(val)
        this.multipleSelection = val
      },
      role_confirm() {
        let self = this
        let data = this.AddPre

        if (!data.name) {
          this.$message({
            message: '请输入姓名',
            type: 'error'
          })
          return
        }
        if (data.mobile.length != 11) {
          this.$message({
            message: '请输入正确的手机号码',
            type: 'error'
          })
          return
        }
        if (!data.sex) {
          this.$message({
            message: '请选择性别',
            type: 'error'
          })
          return

        }

        if (!data.dept_id) {
          this.$message({
            message: '请选择部门',
            type: 'error'
          })
          return

        }


        if (!data.shop_id) {
          this.$message({
            message: '请选择门店',
            type: 'error'
          })
          return

        }
        // if (!data.email) {
        //   this.$message({
        //     message: '请输入邮箱',
        //     type: 'error'
        //   })
        //   return

        // }
        var myreg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!myreg.test(data.email)) {
          this.$message({
            message: '请输入正确的邮箱地址',
            type: 'error'
          })
          return
        }
        if (!data.province) {
          this.$message({
            message: '请输入完整地址',
            type: 'error'
          })
          return

        }
        if (!data.hired_date) {
          this.$message({
            message: '请选择入职时间',
            type: 'error'
          })
          return

        }

        // data.dept_name = ''
        // if(){

        // }
        if (data.dept_id instanceof Array) {
          if (!data.dept_id.length) {
            this.$message({
              message: '请选择部门',
              type: 'error'
            })
            return

          }
          data.dept_id = data.dept_id.join(',')

        }


        if (data.shop_id instanceof Array) {
          data.shop_id = data.shop_id.join(',')
        }
        data.ent_id = this.$store.state.userInfo.ent_info.ent_id
        data.user_id = sessionStorage.getItem('user_id')
        console.log(data);
        
        if (data.id) {
          update_staff({
            data: data
          }).then((res) => {

            if (res.code == 10147) {
              this.$message({
                message: '添加人员成功',
                type: 'success'
              })
              this.editrole = false
              this.AddPre = {
                  // kkkkkk
                  name: '',
                  sex: '',
                  mobile: '',
                  dept_name: '',
                  dept_id: [],
                  post_name: '',
                  hired_date: '',
                  email: '',
                  // area: '',
                  shop_id: [],
                  // flag_in_charge:shifou,
                  ent_id: '',
                  user_id: '',
                  active: '1',
                  province: '', //门店地址：省
                  city: '', //门店地址：市
                  district: '', //门店地址：区
                },

                this.GetSatffList()

            } else if (res.code == '600') {
              this.$message({
                message: '手机号重复',
                type: 'error'
              })
            } else if (res.code =='1200'||res.code =='1100') {
              this.$message({
                message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                type: 'error'
              })
            } else {
              this.$message({
                message: '保存失败',
                type: 'error'
              })
            }
          })
        } else {
          insert_staff({
            data: data
          }).then((res) => {
            if (res.code == 10159) {
              this.$message({
                message: '添加人员成功',
                type: 'success'
              })

              this.role = false
              this.GetSatffList()
              this.AddPre = {
                sex: '',
                // kkkkkk
                name: '',
                mobile: '',
                dept_name: '',
                dept_id: '',
                post_name: '',
                hired_date: '',
                email: '',
                // area: '',
                shop_id: '',
                // flag_in_charge:shifou,
                ent_id: '',
                user_id: '',
                active: '1',
                province: '', //门店地址：省
                city: '', //门店地址：市
                district: '', //门店地址：区
              }
            } else if (res.code == '600') {
              this.$message({
                message: '手机号重复',
                type: 'error'
              })
            } else if (res.code =='1200'||res.code =='1100') {
              this.$message({
                message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                type: 'error'
              })
            } else {
              this.$message({
                message: '保存失败',
                type: 'error'
              })
            }
          })
        }

      },
      role_cancel() {
        this.editrole = false
        this.role = false

        this.AddPre = {
          // kkkkkk
          sex: '',
          name: '',
          mobile: '',
          dept_name: '',
          dept_id: '',
          post_name: '',
          hired_date: '',
          email: '',
          // area: '',
          shop_id: '',
          // flag_in_charge:shifou,
          ent_id: '',
          user_id: '',
          active: '1',
          province: '', //门店地址：省
          city: '', //门店地址：市
          district: '', //门店地址：区
        }
      },

      //导出
      exportDialogDown() {
        this.$refs.downFile.moban_xiazai()
        this.exportDialogShow = false
      },
      // --------------------------------------
      //导入
      importDialog() {
        this.importDialogShow = true
      },

      dialog() {
        this.dialogVisible = true
        console.log(111)
      },

      MuMan_XiaZai() {
        // const data = ['https://www.mlcbr.com/template/餐添大数-员工导入模板.xlsx']
        // this.multiDownLoad(data)
      },
      //   下载多个文件
      multiDownLoad(val) {
        console.log('===============', val);
        for (let i = 0; i < val.length; i++) {
          const iframe = document.createElement('iframe')
          iframe.style.display = 'none'
          iframe.style.height = 0
          iframe.src = val[i]
          document.body.appendChild(iframe)
          setTimeout(res => {
            iframe.remove()
          }, 5 * 60 * 1000)
        }
      },
      QueRenShangChuan() {
        this.$refs.submit.uploadFile()
        this.GetPAll()
        query_ent_primary_manager({
          data: {
            ent_id: this.$store.state.ent_id,
          }
        }).then(res => {

          this.GMID = res.body.data.p_mng_mobile
          this.GetSatffList()
        })
        this.getDepList()
        this.getShopList()

        this.importDialogShow = false
      },
      //当上传的文件改变时
      ShangChuanChange(file, fileList) {
        console.log(file, '|', fileList);
      },
      // 数据导出
      ShuJuDaoChu() {},
    }
  }
</script>

<style scoed lang="scss">
  .People {
    width: 80%;

  }

  .People>ul {
    width: 80%;
  }

  .n_jilian {
    select {
      padding: 10px 2px;
      font-size: 14px;

      option {
        padding: 20px 20px;
        min-height: 40px;
        font-size: 14px;
      }
    }
  }

  .el-main {

    background: red;
  }

  .Essential {
    width: 100%;
    color: #f6f6f3;
    background: url('../../assets/img/beijing.png') no-repeat;
    // height: 860px;
    background-size: 100% 800px;

    .Essential-boxS {
      padding: 0 0 0 0;
      width: 100%;

      .Essential-ul {
        display: flex;
        padding: 54px 0 0 97px;

        li {
          font-size: 20px;
          color: #181616;
          width: 240px;
          font-weight: 500;
        }

        .Essential-li {
          color: #fe5745;
        }
      }

      .Essential-firm {
        padding: 38px 50px 0 0;

        .Essential-noe {
          color: #333;
        }

        &:first-of-type p {
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          color: #fc4353;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .Essential-state {
      .Essential-state-boxS {
        padding: 0 68px 0 65px;
        display: flex;

      }

      ul {
        display: flex;
        margin-top: 43px;
        width: 100%;
        justify-content: center;
        align-items: center;

        li {
          color: #666;
          font-size: 18px;
          display: flex;
          justify-content: center;
          flex: 2;
          margin-right: 10px;

          i {
            margin: 0 10px 0 0;
          }

          span {
            margin-top: 4px;
          }

          &:first-of-type i {
            background: url('../../assets/img/tina-1.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }

          &:nth-child(2) i {
            background: url('../../assets/img/tina-2.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }

          &:nth-child(3) i {
            background: url('../../assets/img/tina-3.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }

          &:nth-child(4) i {
            background: url('../../assets/img/tina-4.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }

          &:nth-child(3) i {
            background: url('../../assets/img/tina-5.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }
        }
      }

      .message-select {
        margin-top: 52px;
      }

      .message-search {
        margin-top: 45px;
        position: relative;

        i {
          z-index: 1;
          position: absolute;
          left: 16px;
          top: 14px;
          background: url('../../assets/img/shou.png') no-repeat;
          width: 22px;
          height: 22px;
        }
      }
    }

    .message-section {
      width: 90%;
      margin: 0 auto;

      .table_x {
        width: 86%;
        margin: 0 auto;
      }
    }
  }

  .Enter-box {
    .el-select-dropdown__item {
      color: #606266;
    }
  }

  .role.height {
    // height: 100%;
  }

  .admin-alter.buttons {
    position: static;
    margin: 0 auto;
  }
</style>

<style lang="scss">
  .el-select-dropdown__item.selected {
    color: #606266;
    font-weight: normal;
  }

  .rs .Essential .Essential-state ul {
    width: 100% !important;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>