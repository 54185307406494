<template>
  <div class="Enter-box structureS">
    <div class="structure">
      <!-- <div class="Essential-box">
            <ul class="Essential-ul">
               <li @click="Essential()" class="">全部人员</li>
               <li @click="structure()" class="Essential-li">部门结构</li>
            </ul>
         </div> -->
      <div class="Essential-structure">
        <div class="Essential-structure-box">
          <ul>
            <li v-for="(item,index) in storeName" :key="index"><span :style=" storeName.length-1 > index && 'color:red'"
                @click="getChrend(item)"> {{item.dept_name}}></span></li>
          </ul>
          <div class="Essential-structure-choice">
            <el-radio-group v-model="radio" size="mini" @change="dep_togle">
              <el-radio label="1">启用部门列表</el-radio>
              <el-radio label="2">停用部门列表</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="Essential-branch">
          <ul>

            <li @click="drawerS = true;selecParenttDep = '' ; dept_name = '';   ">
              <i></i>
              <h4>添加部门</h4>
            </li>
            <li @click="dep_stop" v-if="radio=== '1'">
              <i></i>
              <h4>停用部门</h4>
            </li>
            <li @click="dep_stop('启用')" v-else>
              <i></i>
              <h4>启用部门</h4>
            </li>
            <li @click=" dep_xiugai">
              <i></i>
              <h4>编辑部门</h4>
            </li>
          </ul>
        </div>
        <div class="Essential-table">
          <el-table ref="multipleTable" :data="DeptList" tooltip-effect="dark" style="width: 100%" height="450"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column type="id" width="50"> </el-table-column>
            <el-table-column prop="dept_name" width="240">
              <template slot-scope="scope">
                <!-- <template slot-scope="scope"> -->

                <!-- <img :src="headerCon.qhimg" alt="" v-show="scope.row.dept_type=='2'" /> -->
                <i class="el-icon-school MDICON" v-show="scope.row.dept_type=='2'"></i>
                <!-- </template> -->
                <span @click="getchangData(scope.row)">{{scope.row.dept_name}}</span>
                <!-- <span>{{scope.row.list.length}}</span> -->
                <span>({{scope.row.child_num}})</span>

              </template>
            </el-table-column>
            <el-table-column label="staff_num" width="940" class="Essential-staff">
              <template slot-scope="scope">
                <i class="span_i" style="float:left;margin-top:3px;"></i>
                <span style="margin-left: 10px">{{ scope.row.staff_num }} </span>
              </template>
              <i class="span_i"></i>
              <span>{{ num }}人</span>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button size="mini" :disabled="scope.$index === 0" @click="moveUp(scope.$index, scope.row)">
                  <i class="el-icon-arrow-up"></i>
                  <span>上移</span>
                </el-button>
                <el-button size="mini" :disabled="scope.$index === DeptList.length - 1"
                  @click="moveDown(scope.$index, scope.row)">
                  <i class="el-icon-arrow-down"></i>
                  <span>下移</span>
                </el-button>
                <!-- <el-button type="info" size="mini" round v-if="scope.$index===0">默认</el-button> -->
              </template>
            </el-table-column>
          </el-table>



          <ul class="Name" v-show="tableData&&tableData.length>0" v-if="radio=='1'">
            <li>姓名</li>
            <li>职位</li>
            <li>入职时间</li>
            <li>手机号</li>
            <li>地区</li>
            <li>邮箱</li>
          </ul>

          <el-table v-show="tableData&&tableData.length>0&&radio=='1'" :data="tableData" style="width: 90%" show-header>
            <el-table-column prop="staff_name" label="姓名" width="150">
              <template slot-scope="scope">
                <!-- <i class="span_i"></i> -->
                <span style="margin-left: 10px">{{ scope.row.staff_name }} </span> <span style="color:red;"
                  v-if=" leader_info.staff_id&& leader_info.staff_id==scope.row.staff_id">(主管)</span>
              </template>
            </el-table-column>
            <el-table-column prop="post_name" label="职位" width="140">
            </el-table-column>
            <el-table-column prop="hired_date" label="入职时间" width="160">
            </el-table-column>
            <el-table-column prop="mobile" label="手机号" width="240">
            </el-table-column>
            <el-table-column label="地区" width="245">
              <template slot-scope="scope">
                <span>{{scope.row.province}}</span>
                <span>{{scope.row.city}}</span>
                <span>{{scope.row.district}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="email" label="邮箱" width="250">
            </el-table-column>

          </el-table>




        </div>
      </div>
    </div>
    <el-drawer title="添加部门" :visible.sync="drawerS" ref="drawerS" :with-header="false">
      <div class="structureCharacter">
        <div class="Character-tltle">
          <span></span>
          <p>添加部门</p>
        </div>
        <div class="Character-name">
          <div style="display:flex;">
            <p style="min-width:100px;"><span>*</span>部门名称</p>
            <el-input v-model="dept_name" type="text" placeholder="请输入名称"></el-input>
          </div>


          <div style="display:flex;align-items:center;padding-top:10px;">
            <p style="margin-top:-2px;min-width:100px;"><span>*</span>请选择父部门</p>
            <el-cascader v-model="selecParenttDep" :options="DeptList2" :props="{ multiple: false, checkStrictly: true,children: 'list',emitPath:false,
        label: 'dept_name',value:'dept_id' }" clearable></el-cascader>
          </div>





        </div>




        <div class="admin-alter">
          <a class="admin-confirm" @click="confirm">确定</a>
          <a class="admin-cancel" @click="cancel">取消</a>
        </div>
      </div>
    </el-drawer>
    <el-drawer title="新增分类" :visible.sync="drawerCompile" ref="drawerCompile" :with-header="false">
      <div class="structureCharacter">
        <div class="Character-tltle">
          <span></span>
          <p>编辑部门</p>
        </div>
        <div class="Character-name">
          <div style="display:flex;">
            <p style="min-width:100px;"><span>*</span>部门名称</p>
            <el-input v-model="dept_name" v-if="multipleSelection[0]" :disabled='multipleSelection[0].dept_type=="2"'
              type="text" placeholder="请输入名称"></el-input>
          </div>

          <div style="display:flex;align-items:center;padding-top:10px;">
            <p style="margin-top:-2px;min-width:100px;"><span>*</span>请选择父部门</p>
            <el-cascader v-model="selecParenttDep" :options="DeptList2" v-if="multipleSelection[0]"
                :props="{ multiple: false, checkStrictly: true,children: 'list',
        label: 'dept_name',value:'dept_id' }" clearable></el-cascader>




            <!-- defaultProps: {
          children: 'list',
          label: 'dept_name'
        } -->
          </div>

          <div style="display:flex;align-items:center;padding-top:10px;">
            <p style="margin-top:-2px;min-width:100px;"> 请选择主管</p>
            <el-cascader v-model="selecParenttDep2" :options="proList" :props="{ multiple: false, checkStrictly: true,children: 'list',
        label: 'staff_name',value:'staff_id' }" clearable></el-cascader>


          </div>

          <!-- <div style="display:flex;align-items:center;padding-top:10px;">
                <p style="margin-top:-2px;min-width:100px;"><span>*</span>是否有目标</p>
                <el-radio v-model="mubiao" label="1">有目标</el-radio>
                <el-radio v-model="mubiao" label="2">无目标</el-radio>
          </div> -->
        </div>
        <div class="admin-alter">
          <a class="admin-confirm" @click="CompileConfirm">确定</a>
          <a class="admin-cancel" @click="CompileCancel">取消</a>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import {
    set_ent_dept_info,
    query_ent_dept_list_of_all,
    update_dept_active_batch,
    query_dept_staff_tree_aim_not,
    query_ent_dept_list2,
    update_dept_child_flag,
    set_ent_primary_manager,
    query_ent_primary_manager,
    update_ent_primary_manager,
    query_ent_sub_manager_list_by_module,
    get_dept_list,
    query_all_staff_list_of_dept,
    query_user_info,
    query_ent_sub_manager_list,
    query_all_module_read_power_detail,
    set_ent_sub_manager,
    delete_ent_sub_manager,
    update_dept_sort
  } from '../../api/foodqiye'


  import {
    query_dept_tree,
    query_dept_staff_tree_aim,
    query_dept_staff_list,
    query_staff_list_of_dept_2
  } from "../../api/pulic"
  export default {
    data() {
      return {
        headerCon: {
          tximg: require('@img/tx.png'),
          logo: require('@img/logo.png'),
          qhimg: require('@img/qiehuan.png'),
          tcimg: require('@img/tuichu.png'),
        },
        radio: '1',
        num: '10',
        mubiao: '1',
        part: '',
        URLModles: [],
        dept_name: '',
        drawerS: false,
        drawerCompile: false,
        DeptList: [],
        multipleSelection: [],
        storeName: [],
        selecParenttDep: [],
        DeptList2: [],
        tableData: [],
        proList: [],
        selecParenttDep2: [],
        leader_info: {
          staff_id: ''
        }
      }
    },
    created() {
      this.getDepList()
      this.getDepList2()
      this.storeName.push({
        dept_name: this.$store.state.ent_name,
        dept_id: '0'
      })

    },
    computed: {
      ent_id() {
        return this.$store.state.ent_id
      },
      user_id() {
        return this.$store.state.user_id
      },
      qyInfo() {
        return this.$store.state.qyInfo
      }
    },
    methods: {
      handleChange(val) {
        let data = {}

        data = {
          ent_id: this.$store.state.ent_id,
          active: "1",
          dept_id: val,
          // user_id: this.$store.state.userInfo.user_id
        }

        query_dept_staff_list({
          data: data
        }).then((res) => {

          console.log(res)
          this.proList = res.body.data

        })

      },
      getDepList3() {



        query_dept_tree({
          data: {
            ent_id: this.$store.state.ent_id,

          }
        }).then((res) => {
          this.proList = res.body.data
          console.log('获取部门列表', res);
        })
      },
      GetPerList(val) {

        if (val == '0') {
          this.tableData = []
          return
        }

        query_dept_staff_list({
          data: {
            ent_id: this.$store.state.ent_id,
            active: "1",
            dept_id: val,
            // user_id: this.$store.state.userInfo.user_id
          }
        }).then((res) => {
          //
          this.leader_info = res.body.leader_info
          console.log(res)

        })
        query_staff_list_of_dept_2({
          data: {
            ent_id: this.$store.state.ent_id,

            active: "1",
            dept_id: val,
            user_id: this.$store.state.userInfo.user_id
          }
        }).then((res) => {
          this.tableData = res.body.data
          console.log(res.body.data)

        })
      },
      getDepList2() {



        query_dept_tree({
          data: {
            ent_id: this.$store.state.ent_id,
            active: '1'
          }
        }).then((res) => {

          this.DeptList2 = [{
            list: res.body.data,
            dept_name: '根部门',
            dept_id: '0'
          }]
          this.leader_info = res.body.leader_info

          // this.selecParenttDep2=[res.body.leader_info.staff_id]
          console.log('获取部门列表', res);
        })
      },
      //query_dept_staff_list  获取人员列表接口
      getQiyeName() {},
      getChrend(val) {
        this.getDepList(val.dept_id)
        this.GetPerList(val.dept_id)
        let list = ['z', 's', 'l']
        let x = 's'
        let index = this.storeName.findIndex(X => X.dept_id === val.dept_id)
        this.storeName = this.storeName.slice(0, index + 1)
      },
      dep_xiugai() {
        if (this.multipleSelection[0]) {

        } else {
          this.$message.warning('请选择部门')

        }

        if (this.multipleSelection.length > 1) {
          this.$message.warning('只能选择一个')
        } else {

          console.log(this.multipleSelection[0])
          this.handleChange(this.multipleSelection[0].dept_id)

          this.selecParenttDep =this.multipleSelection[0].parent_id
          this.dept_name = this.multipleSelection[0].dept_name

          this.selecParenttDep2=this.multipleSelection[0].dept_leader_id
          // selecParenttDep2
          // console.log()
          this.drawerCompile = true
        }
      },
      getchangData(val) {
        this.selectDeptId = val.dept_id
        // this.handleChange()

        this.storeName.push(val)
        this.getDepList(val.dept_id)
        this.GetPerList(val.dept_id)
        console.log(val,'dddddddddddddddddddddddddddddddddddd')
        console.log(this.selectDeptId,'dddddddddddddddddddddddddddddddddddd')

      },
      async getDepList(id) {

        let flag = null
        const parent_id = id || '0'
        const ent_id = this.ent_id
        const user_id = this.user_id
        const param = {
          active: "1",
          ent_id,
          user_id,
          parent_id
        }
        const param_flag = { //停用
          ent_id,
          user_id,
          dept_id: '0',
          child_flag: '1' //是否有子叶子节点，1是，0否
        }
        const data = await query_ent_dept_list_of_all({
          data: param
        })
        const res = await update_dept_child_flag({
          data: param_flag
        })
        if (data.code === '10168') {
          console.log(data.body.data, '55555555555555555')
          this.selecParenttDep2=[data.body.data.dept_leader_id]
          this.DeptList = data.body.data
          flag = true
        }


        return flag
      },
      dep_togle(val) {
        const parent_id = this.storeName.slice(-1)[0].dept_id || '0'
        val === '1' && this.getDepList(parent_id)
        val === '2' && this.get_dep_stop()

        if (val == '2') {
          this.storeName.length = 1
        }
      },
      async get_dep_stop() {
        let flag = null
        const ent_id = this.ent_id
        const user_id = this.user_id
        const param = {
          active: "0",
          ent_id,
          user_id
        }
        const data = await query_ent_dept_list2({
          data: param
        })
        if (data.code === '10168') {
          this.DeptList = data.body.data
          flag = true
        }
        return flag
      },
      confirm() {
        if (this.dept_name === '') {
          return this.$message.warning('请输入部门名称')
        }
        if (this.selecParenttDep == '') {
          return this.$message.warning('请选择父部门')
        }


        // const parent_id = this.storeName[this.storeName.length-1].dept_id || '0'
        const param = {
          ent_id: this.ent_id,
          user_id: this.user_id,
          // dept_id:'',//新增无，更新传部门id	,
          dept_name: this.dept_name, //部门名字,
          // parent_id, //父id,
          child_flag: '0', //是否有子部门：1有，0没有,
          //dept_leader_id:'',	//部门负责人id,
          aim_prop: this.mubiao, //是否有目标：1有，0没有
          active: '1',
          parent_id: this.selecParenttDep
        }
        set_ent_dept_info({
          data: param
        }).then(res => {
          if (res.code==200) {
            this.dept_name = ''

            this.$message.success('添加部门成功')
            this.getDepList(this.selecParenttDep)
            this.selecParenttDep = ''

            this.getDepList2()
          }
          if (res.code =='1200'||res.code =='1100') {
            this.$message({
              message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
              type: 'error'
            })
          }
        })

        this.drawerS = false
      },
      cancel() {
        this.drawerS = false
      },
      dep_start() {

      },
      CompileConfirm() {

        if (this.dept_name === '') {
          return this.$message.warning('请输入部门名称')
        }
        if (this.selecParenttDep.length < 1) {
          return this.$message.warning('请选择父部门')
        }
        // if (!this.selecParenttDep2) {
        //   return this.$message.warning('请选择部门主管')
        // }
        const parent_id = this.selecParenttDep[this.selecParenttDep.length-1]
        const param = {
          ent_id: this.ent_id,
          user_id: this.user_id,
          // dept_id: this.multipleSelection[0].dept_id, //新增无，更新传部门id,
          dept_id: this.selectDeptId,
          dept_name: this.dept_name, //部门名字,
          // parent_id:   parent_id instanceof Array? parent_id[parent_id.length -1]:parent_id, //父id, Array.isArray(objectName)
          parent_id: Array.isArray( parent_id) ? parent_id[parent_id.length -1]:parent_id, //父id,
          child_flag: '0', //是否有子部门：1有，0没有,
          dept_leader_id: this.selecParenttDep2[0], //部门负责人id,
          aim_prop: this.mubiao, //是否有目标：1有，0没有
          active: '1'
        }
        set_ent_dept_info({
          data: param
        }).then(res => {
          if (res.code =200) {
            this.dept_name = ''
            this.$message.success('修改部门成功')
            if (this.radio === '1') {
              this.getDepList(parent_id)
            } else {
              this.get_dep_stop()
            }
          }
          if (res.code =='1200'||res.code =='1100') {
            this.$message({
              message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
              type: 'error'
            })
          }
        })
        this.drawerCompile = false
      },
      CompileCancel() {
        this.drawerCompile = false
      },
      Essential() {
        this.$router.push('/messageEssential')
      },
      structure() {},
      handleSelectionChange(val) {
        this.multipleSelection = val
      },
      dep_stop(val) {
        // update_dept_active_batch
        let active = '0'
        if (val === '启用') {
          active = '1'
        }
        const ent_id = this.ent_id
        const user_id = this.user_id
        const param_stop = {
          ent_id,
          user_id,
          active, // 1.启用 0 停用
          dept_ids: this.multipleSelection.map(x => x.dept_id).join(',') // 部门id 逗号分割
        }
        const param_flag = { //停用
          ent_id,
          user_id,
          dept_id: '0',
          child_flag: '1' //是否有子叶子节点，1是，0否
        }
        update_dept_active_batch({
            data: param_stop
          })
          .then(res => {
            if (res.code === '10247') {
              this.$message.warning('当前部门下有人，无法停用')
            } else if (res.code === '10209') {
              active === '0' ? this.$message.success('部门停用成功') : this.$message.success('部门启用成功')
              if (active == '1') {
                this.radio = '1'
                this.dep_togle()
              }
              let one = active === '0' ? this.getDepList() : this.get_dep_stop()
              return one
            } else {
              active === '0' ? this.$message.warning('停用失败,先选择') : this.$message.warning('启用失败，先选择')
            }
          })
          .then(res => {
            if (res) {
              return update_dept_child_flag({
                data: param_flag
              })
            }
          })
          .then(res => {
            //  console.log(res,'----')
          })
        // child_flag: "1"
        // dept_id: "0"
        // ent_id: "QY1580619678461k6gx"
        // query_ent_dept_list_of_all
        // update_dept_child_flag //更改部门的child_flag
        //  console.log()
        // this.getDepList()
      },
      // 上移
      moveUp(index, row) {
        var that = this
        if (index > 0) {
          const upDate = that.DeptList[index - 1]
          that.DeptList.splice(index - 1, 1)
          that.DeptList.splice(index, 0, upDate)

          let arr = []


          for (const i in that.DeptList) {
            arr.push({
              dept_id: that.DeptList[i].dept_id,
              sort: Number(i) + 1
            })
          }
          update_dept_sort({
            data: {
              ent_id: this.$store.state.ent_id,
              list: arr
            }
          })
          // update_dept_sort


        } else {
          alert('已经是第一条，不可上移')
        }
      },
      // 下移
      moveDown(index, row) {
        var that = this
        if (index + 1 === that.DeptList.length) {
          alert('已经是最后一条，不可下移')
        } else {
          //(index)
          // 保存下一条数据
          const downDate = that.DeptList[index + 1]
          // 删除下一条数据
          that.DeptList.splice(index + 1, 1)
          // 增添被删除的那一条数据
          that.DeptList.splice(index, 0, downDate)


          let arr = []
          for (const i in that.DeptList) {
            arr.push({
              dept_id: that.DeptList[i].dept_id,
              sort: Number(i) + 1
            })
          }
          update_dept_sort({
            data: {
              ent_id: this.$store.state.ent_id,
              list: arr
            }
          })
        }
      }
    }
  }

  // 添加人员调用 insert_staff
  // 设置部门信息单一 set_ent_dept_info
  // 获取部门列表 query_ent_staff_list
  // query_ent_staffNum_registerNum

  // query_ent_dept_list_of_all
  // update_dept_child_flag
  // query_ent_staff_list
  //
</script>

<style scoed lang="scss">
  .el-main {

    background: #ffffff;
  }

  .structureS {
    .structureCharacter {
      padding: 30px;
      box-sizing: border-box;

    }

    .Character-name {
      display: flex;
      flex-flow: column nowrap;

      p {
        span {
          color: #FC4353;
        }

        color: #333333;
        font-size: 16px;
        margin: 10px 20px 0 0;

      }

      .el-input {
        width: 240px;
        height: 49px;
        border-radius: 10px;
      }
    }

    padding-top: 20px;
  }

  .structure {
    width: 100%;
    color: #f6f6f3;

    background: url('../../assets/img/beijing2.png') no-repeat;
    height: 1000px;
    background-size: 100% 800px;

    .Essential-box {
      padding: 0 0 0 0;
      display: flex;
      justify-content: space-between;

      .Essential-ul {
        display: flex;
        padding: 38px 0 0 97px;

        li {
          font-size: 20px;
          color: #666666;
          width: 280px;
          font-weight: 500;
        }

        .Essential-li {
          color: #fe5745;
        }
      }
    }

    .Essential-branch {
      width: 90%;
      height: 70px;
      margin: 20px auto 0 auto;
      background: linear-gradient(180deg, #fc4353, #ff5e41);
      border-radius: 15px 15px 0px 0px;

      ul {
        display: flex;
        margin-left: 50px;
        box-sizing: border-box;
        padding-top: 16px;

        li {
          font-size: 16px;
          color: #fff;
          width: 131px;
          height: 38px;
          border: 1px solid #ffffff;
          border-radius: 10px;
          display: flex;
          margin-right: 100px;
          justify-content: center;
          box-sizing: border-box;
          line-height: 26px;
          padding-top: 3px;

          i {
            margin-right: 10px;
          }

          &:first-of-type i {
            width: 28px;
            height: 29px;
            background: url('../../assets/img/bian-1.png');
            background-size: 28px 29px;
            display: block;
          }

          &:nth-child(2) i {
            width: 28px;
            height: 29px;
            background: url('../../assets/img/bian-2.png');
            background-size: 28px 29px;
            display: block;
          }

          &:last-of-type i {
            width: 20px;
            height: 21px;
            background: url('../../assets/img/bian-3.png');
            background-size: 20px 21px;
            display: block;
            margin-top: 4px;
          }
        }
      }
    }

    .Essential-table {
      -moz-box-shadow: 0px 0px 10px #e6e6e8;
      -webkit-box-shadow: 0px 0px 10px #e6e6e8;
      box-shadow: 0px 0px 10px #e6e6e8;

      tbody {
        tr {
          &:nth-child(3) td .cell {
            display: flex;
          }
        }
      }
    }

    .Essential-staff {
      display: flex;
    }

    .Essential-structure {
      .Essential-structure-box {
        padding: 40px 100px 0 100px;
        display: flex;
        justify-content: space-between;

        ul {
          display: flex;
          margin-top: 60px;

          li {
            color: #666;
            font-size: 18px;
            display: flex;
            margin-right: 10px !important;
            margin-left: 0;
            span {
              margin-top: 4px;
            }
          }
        }

        .Essential-structure-choice {
          margin-top: 108px;
        }
      }

      .Essential-table {
        width: 90%;
        margin: 0 auto;

        .has-gutter th,
        .has-gutter tr {
          display: none;
        }

        .span_i {
          background: url('../../assets/img/ren.png') no-repeat;
          width: 18px;
          height: 15px;
          display: block;
        }
      }
    }
  }

  .Name {
    width: 1200px;
    padding: 20px;

    li {

      // margin-left: 100px;
      // width: 18%;
      // width: 20%;
      color: black;
      float: left;
    }

    li:nth-child(1) {
      width: 140px;
    }

    li:nth-child(2) {
      width: 150px;
    }

    li:nth-child(3) {
      width: 160px;
    }

    li:nth-child(4) {
      width: 230px;
    }

    li:nth-child(5) {
      width: 240px;
    }
  }

  .MDICON {
    font-size: 24px;
    margin-right: 5px;
    color: red;
  }
</style>

<style>
  /* .el-table__body-wrapper is-scrolling-none{
  height: auto  !important;
} */
  .structureS .el-table {
    height: auto !important;
  }

  .structureS .el-table__body-wrapper {
    height: auto !important;
  }
</style>